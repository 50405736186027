


























import {Component, Prop, Vue} from 'vue-property-decorator';
import JobUserDataInterface from '@/interfaces/JobUserData.interface';
import moment, {Moment} from 'moment';
import JobOccurrence from '@/models/JobOccurrence.ts';

@Component({
  components: {
    CalendarEventTile: () => import(
        '@/components/shared/CalendarEventTile.component.vue'),
  },
})
export default class CalendarViewDayComponent extends Vue {

  @Prop({default: () => []})
  public events!: Array<{ jobOccurrence: JobOccurrence, userData: JobUserDataInterface[] }>;

  @Prop({default: () => moment()})
  public displayDate!: Moment;

  private INTERVAL_WIDTH = 60;
  private HOURS_PER_DAY = 24;

  private get nowX(): number {
    const hours = new Date().getHours();
    const minutes = new Date().getMinutes();
    return hours * this.INTERVAL_WIDTH + (minutes / 60 * this.INTERVAL_WIDTH);
  }

  /**
   * Extract the events from the array and push them to a new 2D one
   * @private
   */
  private get populateCalendar() {
    const populatedCalendar: Array<Array<{ jobOccurrence: JobOccurrence, userData: JobUserDataInterface[] }>> = [[]];
    for (const value of this.events) {
      // If value is undefined, continue
      if (!value) {
        continue;
      }
      // calculate startMoment
      const lastRow = populatedCalendar[populatedCalendar.length - 1];
      const lastEvent = lastRow[lastRow.length - 1];
      if (!lastEvent) {
        // populatedCalendar has no entries
        populatedCalendar[populatedCalendar.length - 1].push(value);
        continue;
      }
      populatedCalendar.push([value]);
    }
    return populatedCalendar;
  }


  /**
   * Calculate the x-Coord for the event in the Calendar
   * @param jobOccurrence
   * @param isLast Flag for LineBreak
   * @private
   */
  private calculateEventCoords(jobOccurrence: JobOccurrence, isLast: boolean) {
    const hours = +moment(jobOccurrence.startTime).format('HH') * this.INTERVAL_WIDTH;
    const minutes = +moment(jobOccurrence.startTime).format('mm') / 60 * this.INTERVAL_WIDTH;
    return {
      left: hours + minutes + 'px',
      minWidth: 180,
      maxWidth: 180,
      position: isLast ? 'relative' : 'absolute',
    };
  }


  private showEventDetails(event: any) {
    this.$emit('showDetails', event);
  }
}
